import { accountApiService } from "~/services/accountApiService";
import { useAuthStore } from "~/store/auth.js";
import { notifyError, notifySuccess } from "~/utils/notifications.js";

export const useAccountApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);
  const responseError = ref();

  // Use Auth Store
  const { clearLocalStorage, saveUserInfo } = useAuthStore();
  const { fetchUserInfo, updateUserProfile, signOut } = accountApiService();

  const processResponse = (response) => {
    if (response?.status === 200) return response.data.data;
    else throw new Error("Unexpected response status: " + response?.status);
  };

  const fetchUserInfoApi = async () => {
    try {
      loading.value = true;
      const response = await fetchUserInfo();
      const userData = processResponse(response);
      saveUserInfo(userData);
    } catch (err) {
      console.error("==> error fetching user: ", err);
      error.value = err.message || "Failed to fetch user info";
      notifyError({ title: "Fetch Error", text: error.value });
    } finally {
      loading.value = false;
    }
  };

  const updateUserProfileApi = async (data) => {
    try {
      loading.value = true;
      const response = await updateUserProfile(data);
      switch (response.status) {
        case 200:
          await fetchUserInfoApi();
          notifySuccess(response.data.data.message);
          break;
        case 409:
        case 404:
        case 400:
          notifyError({
            title: response.data?.message || "An unexpected error occurred",
            text: response.data?.details || "",
          });
          break;
        default:
          notifyError({
            title: response.data?.message || "An unexpected error occurred",
            text: response.data?.details || null,
          });
          break;
      }
    } catch (err) {
      error.value = err.message || "Failed to fetch user info";
      notifyError({ title: "Fetch Error", text: error.value });
    } finally {
      loading.value = false;
    }
  };

  const signOutUserApi = async () => {
    try {
      loading.value = true;
      await signOut();
      clearLocalStorage();
      navigateTo("/signin");
    } catch (err) {
      error.value = err.message || "Failed to sign out";
      notifyError({ title: "Sign Out Error", text: error.value });
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    responseError,
    loading,
    fetchUserInfoApi,
    updateUserProfileApi,
    signOutUserApi,
  };
};
